
















import { RountName } from '@/router';
import { AccountStore } from '@/store/modules';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'MypageSetting',
  setup(_, { root }) {
    function handleClickLogout() {
      AccountStore.logout();

      root.$router.push('/');
    }

    return {
      RountName,

      handleClickLogout,
    };
  },
});
